import React from "react";
import styled from "styled-components";
import { device } from "../../../theme";
import SVG from "react-inlinesvg";
import BackgroundImage from "gatsby-background-image";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

export const FeatureWrapper = styled.section`
  padding: 0;
`;

export const FeatureList = styled(Tabs)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const FeatureNav = styled(TabList)`
  width: calc(100% / 3);
  list-style: none;
  padding: 0;
  margin: 0;

  @media ${device.large}{
    width: 100%;
  }
`;

export const NavTab = styled(Tab)`
  border-top: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
  padding: 60px 50px;
  border-left: 10px solid rgba(17, 17, 17, 0);
  background-color: #ffffff;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;

  &:nth-child(n + 1) {
    margin-top: -1px;
  }

  svg {
    path,
    g {
      fill: #111;
      transition: all 0.3s ease;
    }
  }

  &:hover {
    background-color: #f8f8f8;
  }
  
  &:focus {
    box-shadow: none;
    border-color: none;
    outline: none;
  }

  &.is-selected {
    border-left: 10px solid #111;
    background-color: #f4f4f4;

    svg {
      path,
      g {
        fill: #449ac7;
      }
    }
  }

  @media ${device.large}{
    padding: 25px 50px;
  }

  @media ${device.small}{
    padding: 15px 20px;
  }
`;

const TabIcon = styled(SVG)`
  height: 50px;
  width: auto;
  padding-right: 20px;

  @media ${device.small}{
    height: 35px;
  }
`;

const TabTitleWrapper = styled.div`
  text-align: left;
  float: right;

  .title {
    display: block;
    font-size: 1.07em;
    letter-spacing: 3px;
    color: #111;
    text-transform: uppercase;
    font-weight: 500;
  }

  .subtitle {
    display: block;
    font-size: 0.8em;
    letter-spacing: 2px;
    color: #999;
    text-transform: uppercase;
  }
`;

export const TabContent = ({ icon, title, subtitle }) => {
  return (
    <>
      <TabIcon src={icon} />
      <TabTitleWrapper>
        <span className="title">{title}</span>
        <span className="subtitle">{subtitle}</span>
      </TabTitleWrapper>
    </>
  );
};

export const PanelWrapper = styled.div`
  width: calc(200%/3);
  height: auto;

  @media ${device.large}{
    width: 100%;
  }
`;

export const FeaturePanel = styled(TabPanel)`
  display: flex;
  background: #f4f4f4;
  transition: all 0.3s ease;

  &.is-selected {
    height: calc((100%) + 1px);
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    margin-top: -1px;
  }

  @media ${device.small}{
    flex: column;
    flex-wrap: wrap;
  }
`;

export const PanelContent = styled.div`
  width: 50%;
  padding: 50px;
  padding-top: 120px;

  h4 {
    display: block;
    font-size: 1.07em;
    letter-spacing: 3px;
    color: #111;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 5px;
    margin: 0;
  }

  h5 {
    display: block;
    font-size: 0.8em;
    letter-spacing: 2px;
    color: #999;
    text-transform: uppercase;
  }

  p {
    font-size: 0.875em;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 1.8;
    color: #444;
    text-align: justify;
  }

  a.button {
    margin-top: 50px;
    position: relative;
    display: inline-block;
    padding: 8px 38px;
    background: #111;
    color: #ececec;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.8em;
    border-radius: 0;
    transition: all 0.3s;
    border: 1px solid rgba(17,17,17,0);

    &:hover {
      border: 1px solid rgba(17,17,17,1);
      background: #fff;
      color: #111;
    }

    @media ${device.small} {
      margin-top: 20px;
    }
  }

  @media ${device.small}{
    padding-top: 30px;
    width: 100%;
  }
`;

export const PanelMedia = styled(BackgroundImage)`
  width: 50%;
  height: auto;

  @media ${device.small}{
    width: 100%;
    order: -1;
    height: 175px;
  }
`;