import React from 'react';
import styled from "styled-components";
import { device } from "../../../theme";
import BackgroundImage from 'gatsby-background-image';

export const AboutSectionWrap = styled.section`
  padding: 50px 0;
  .section-pt-md {
    padding-top: 70px;
  }
  @media ${device.xlarge} {
    .col-2.offset-1 {
      margin-left: 20vw;
    }
  }
  @media ${device.medium} {
    padding: 70px 0;
    .col-1.offset-1,
    .col-2.offset-1 {
      max-width: 80vw;
      min-width: 80vw;
      flex: 0;
      margin-left: 10vw;
    }
    .section-pt-md {
      padding-top: 15px;
    }
    .project-type {
      flex-basis: 50%;
      max-width: 50%;
      width: 50%;
      margin: 30px 0;
    }
  }
  @media ${device.small} {
    padding: 10vw 0;
    .project-type {
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      margin: 0;
      padding: 15px 0;
    }
  }
`;

export const AboutTitleContainer = styled.header`
  display: inline-block;
  width: 100%;
  padding: 30px 0;
`;

export const AboutContentContainer = styled.main`
  display: inline-flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 25px 6.25vw;
  @media ${device.medium} {
    padding: 25px 0;
  }
`;

export const AboutCopy = styled.article`
  width: 70%;
  padding: 0px 6.25%;
  text-align: justify;
  @media ${device.medium} {
    padding: 25px 8%;
    width: 100%;
  }
`;

const Testimonial = styled.aside`
  text-align: justify;
  width: 30%;
  padding: 0;
  order: -1;
  
  blockquote {
    color: #444;
    font-weight: 400;
  }

  cite {
    letter-spacing: 1.1px;
    color: #777;
    float: right;
    
    :before {
      content: '- ';
    }

    @media ${device.medium} {
      float: none;
    }

    span {
      font-weight: 700;
      color: #444;
      text-transform: uppercase;
    }
  }
  @media ${device.medium} {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
`;

const ImageWrap = styled.aside`
  width: 30%;
  height: 100%;
  order: -1;
  @media ${device.medium} {
    order: 2;
    padding-bottom: 50px;
    width: 100%;
  }
`;

const Image = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  min-height: 450px;
  @media ${device.medium}{
    min-height: 175px;
  }
`;

export const AboutAccent = ({type, name, company, testimonial, image}) => {
  if (type === 'testimonial') {
    return (
      <Testimonial>
        <blockquote>{testimonial}</blockquote>
        <cite><span>{name}</span>, {company}</cite>
      </Testimonial>
    );
  } else if (type === 'image') {
    return (
      <ImageWrap>
        <Image fluid={image} />
      </ImageWrap>
    );
  } else {
    return (
      <h5>Missing Asset</h5>
    );
  };
};
