import React from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Hero from '../components/hero';
import AccentRow from '../components/accentRow';
import About from '../containers/about/about';
import Features from '../containers/about/features';
import Testimonial from '../containers/home/testimonial';
import { graphql } from 'gatsby'

export const aboutPageData = graphql`
  query aboutPageTemplateQuery {
    sanityAbout {
      hero {
        title
        subtitle
        image {
          alt
          asset {
            fluid(maxWidth: 1920, maxHeight: 750) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      openGraph {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const AboutPage = (props) => {
  const { data } = props
  const pageData = data.sanityAbout
  const hero = pageData.hero
  const seoMeta = pageData.seo;
  const openGraph = pageData.openGraph;

  return (
    <Layout headerLayout={2}>
    <SEO
      title={seoMeta?.seo_title}
      ogtitle={openGraph?.title}
      description={seoMeta?.meta_description}
      ogdescription={openGraph?.description}
      image={openGraph?.image?.asset?.fluid}
      pathname={props.location.pathname}
    />
      <Hero
        title={hero.title}
        subhead={hero.subtitle}
        image={hero.image.asset.fluid}
        alt={hero.image.alt}
      />
      <About />
      <AccentRow />
      <Features />
      <Testimonial />
    </Layout>
  );
};

export default AboutPage;
