import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import {
  FeatureWrapper,
  FeatureList,
  FeatureNav,
  NavTab,
  TabContent,
  FeaturePanel,
  PanelWrapper,
  PanelContent,
  PanelMedia
} from './features.stc'

const Features = () => {
  const aboutPageFeaturesData = useStaticQuery(graphql`
    query aboutPageFeaturesQuery {
      sanityAbout {
        aboutDetails {
          _key
          title
          subtitle
          icon {
            icon {
              asset {
                url
              }
            }
          }
          feature {
            title
            subtitle
            image {
              asset {
                fluid(maxHeight: 800, maxWidth: 800) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
            }
            cta {
              copy
              path {
                page {
                  ... on SanityServices {
                    slug {
                      current
                    }
                  }
                  ... on SanityProjects {
                    slug {
                      current
                    }
                  }
                  ... on SanityOurProcess {
                    slug {
                      current
                    }
                  }
                  ... on SanityHome {
                    slug {
                      current
                    }
                  }
                  ... on SanityContact {
                    slug {
                      current
                    }
                  }
                  ... on SanityAbout {
                    slug {
                      current
                    }
                  }
                  ... on SanityProject {
                    slug {
                      current
                    }
                  }
                  ... on SanityService {
                    slug {
                      current
                    }
                  }
                }
              }
            }
          }
        }
        _rawAboutDetails
      }
    }
  `);

  const details = aboutPageFeaturesData.sanityAbout.aboutDetails;
  const rawDetails = aboutPageFeaturesData.sanityAbout._rawAboutDetails;

  return (
    <FeatureWrapper>
      <FeatureList
        selectedTabClassName="is-selected"
        selectedTabPanelClassName="is-selected"
      >
        <FeatureNav>
        {details.map((data) => {
          return (
            <NavTab key={data._key}>
              <TabContent
               title={data.title}
               subtitle={data.subtitle}
               icon={data.icon.icon.asset.url}
              />
            </NavTab>
          );
        })}
        </FeatureNav>
        <PanelWrapper>
        {details.map((data, i) => {
          return (
            <FeaturePanel key={data._key}>
              <PanelContent>
                <h4>{data.feature.title}</h4>
                <h5>{data.feature.subtitle}</h5>
                <BlockContent blocks={rawDetails[i].feature.copy} />
                <Link
                  to={`/${data.feature.cta.path.page.slug.current}`}
                  className="button"
                >
                  {data.feature.cta.copy}
                </Link>
              </PanelContent>
              <PanelMedia fluid={data.feature.image.asset.fluid} />
            </FeaturePanel>
          );
        })}
        </PanelWrapper>
      </FeatureList>
    </FeatureWrapper>
  );
};

export default Features;

// <p>-------</p>
