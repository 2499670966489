import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import SectionTitle from "../../../components/shared/section-title";
import PortableText from "../../../components/shared/portableText";
import {
  AboutSectionWrap,
  AboutTitleContainer,
  AboutContentContainer,
  AboutCopy,
  AboutAccent
} from "./about.stc";

const About = () => {
  const aboutPageData = useStaticQuery(graphql`
    query aboutPageQuery {
      sanityAbout {
        headline
        subhead
        _rawCopy

        aside {
          ... on SanityImage {
            _type
            asset {
              fluid(maxHeight: 800, maxWidth: 1980) {
                ...GatsbySanityImageFluid
              }
            }
          }
          ... on SanityTestimonial {
            _type
            name
            testimonial
            company
          }
        }
      }
    }
  `);

  const {
    headline,
    subhead,
    _rawCopy: copy,
    aside: [{ _type: type }],
    aside: [{ name: asideName }],
    aside: [{ testimonial }],
    aside: [{ company }],
    aside: [{ asset }],
  } = aboutPageData.sanityAbout;

  // Set fluid image to const if exists
  const asideImage = asset ? asset.fluid : undefined;

  return (
    <AboutSectionWrap>
      <AboutTitleContainer>
        <SectionTitle title={headline} subtitle={subhead} />
      </AboutTitleContainer>
      <AboutContentContainer>
        {copy &&
          <AboutCopy>
            <PortableText blocks={copy} />
          </AboutCopy>
        }
        <AboutAccent
          type={type}
          name={asideName}
          company={company}
          testimonial={testimonial}
          image={asideImage}
        />
      </AboutContentContainer>
    </AboutSectionWrap>
  );
};

export default About;
